import { HeaderLogoWithAddress, HeaderLogoWithTitleContent } from "../common/HeaderPdfFormTemplate";
import CONFIG from "config/config";

const FORM_NAME = `FormDrugUseRecord`;

const FormDrugUseRecord = async (props: any) => {
  // Add Header
  var headerForm: any;

  if (CONFIG.COMPANY === "CU") {
    headerForm = await HeaderLogoWithTitleContent({
      hideLogo: true, // CU ไม่ต้องแสดง Logo บน Form นี้
      form: FORM_NAME,
      font: "THSarabunNew",
      pageMargins: [15, 95, 15, 30],
      headerMargins: [40, 20, 20, 0],
      styles: {
        tableStyle: {
          margin: [0, 5, 0, 15],
        },
        titleHeader: {
          fontSize: 20,
          bold: true,
          alignment: "center",
        },
        subTitleHeader: {
          fontSize: 18,
          alignment: "center",
        },
        dateHeader: {
          fontSize: 16,
          alignment: "center",
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
          fillColor: "lightgray",
        },
        tableData: {},
      },
      titleName: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
      titleContent: [
        {
          text: "รายงานการใช้ยาเสพติดให้โทษและวัตถุออกฤทธิ์ต่อจิตและประสาท",
          style: "subTitleHeader",
        },
        {
          text: `ประจำวันที่ ${props?.start_date} ถึง ${props?.end_date} หน่วยงาน ${props?.ward}`,
          style: "dateHeader",
        },
      ]
    })
  } else {
    headerForm = await HeaderLogoWithAddress({
      form: FORM_NAME,
      font: "THSarabunNew",
      pageMargins: [15, 140, 15, 30],
      logoHeight: 30,
      styles: {
        tableStyle: {
          margin: [0, 5, 0, 15],
        },
        titleHeader: {
          fontSize: 20,
          bold: true,
          alignment: "center",
        },
        subTitleHeader: {
          fontSize: 18,
          alignment: "center",
        },
        dateHeader: {
          fontSize: 16,
          alignment: "center",
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
          fillColor: "lightgray",
        },
        tableData: {},
      },
      titleContent: [
        {
          text: "รายงานการใช้ยาเสพติดให้โทษและวัตถุออกฤทธิ์ต่อจิตและประสาท",
          style: "subTitleHeader",
        },
        {
          text: `ประจำวันที่ ${props?.start_date} ถึง ${props?.end_date} หน่วยงาน ${props?.ward}`,
          style: "dateHeader",
        },
      ]
    })
  }
  const {font, styles, images} = headerForm;

  return {
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 12,
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    images: {...images},
    styles: {
      ...styles,
    },
    ...headerForm,
    // header: {
    //   margin: [40, 20, 20, 0],
    //   stack: [
    //     {
    //       layout: "noBorders",
    //       table: {
    //         widths: ["100%"],
    //         heights: [80],
    //         body: [
    //           [
    //             {
    //               margin: [0, 0, 0, 0],
    //               stack: [
    //                 {
    //                   text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
    //                   style: "header",
    //                 },
    //                 {
    //                   text: "รายงานการใช้ยาเสพติดให้โทษและวัตถุออกฤทธิ์ต่อจิตและประสาท",
    //                   style: "subheader",
    //                 },
    //                 {
    //                   text: `ประจำวันที่ ${props?.start_date} ถึง ${props?.end_date} หน่วยงาน ${props?.ward}`,
    //                   style: "dateheader",
    //                 },
    //               ],
    //             },
    //           ],
    //         ],
    //       },
    //     },
    //   ],
    // },
    content: [
      {
        style: "tableStyle",
        table: {
          headerRows: 1,
          widths: [45, "*", 40, "*", "auto", "auto", 45, 55, 30, 30, 30, "*", "*"], // Old: [45, "*", 40, "*", "*", "*", 45, 55, 55, 30, 30, "*", "*"]
          body: [
            [
              {
                text: "วันที่สั่งใช้ยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "แพทย์ผู้สั่ง",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "HN (AN)",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ชื่อ-นามสกุล",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ชื่อยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ยาและวิธีใช้ยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวนจ่าย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "วันที่บริหารยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "เวลาบริหารยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ใช้",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ทิ้ง",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "พยาบาล 1",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "พยาบาล 2",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...(props?.data || []).map((item: any) => [
              {
                text: item.order_date_text,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.order_by_fullname,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.hn_an,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient_fullname,
                style: "tableData",
                alignment: "left",
              },
              {
                text: item.drug_label_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.drug_item_label,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.drug_quantity_text,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.admin_date_text,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.admin_time_text,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.apply_text,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.reject_text,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.nurse1_fullname,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.nurse2_fullname,
                style: "tableData",
                alignment: "center",
              },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      let pageText = currentPage.toString() + " of " + pageCount;
      return { text: pageText, alignment: "center" };
    },
  };
};

export default FormDrugUseRecord;

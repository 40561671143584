import React, { useMemo, useState } from "react";
import ModRecontinueMedUX from "./ModRecontinueMedUX";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { Checkbox } from "semantic-ui-react";
import { alignCenter } from "react-lib/apps/common/PureReactTable";
import { useIntl } from "react-intl";

const ModRecontinueMed = (props: any) => {
  const intl = useIntl();
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [selectedItemIds, setSelectedItemIds] = useState<any>([]);
  const [checkedDoctor, setCheckedDoctor] = useState<boolean>(false);
  const [checkedDivision, setCheckedDivision] = useState<boolean>(false);

  const drugContinuePlanItems = useMemo(() => {
    return (props.modRecontinueMed?.continuePlanItems || []).map((item: any, index: number) => ({
      ...item,
      selected: (
        <Checkbox
          checked={selectedItemIds.includes(item.id)}
          onClick={() => {
            handleSelectedItem(item);
          }}
        />
      ),
      code: alignCenter(item.code),
      quantity: alignCenter(item.quantity),
      unit_name: alignCenter(item.unit_name),
    }));
  }, [props.modRecontinueMed?.continuePlanItems, selectedItemIds]);

  const handleSelectedItem = (item: any) => {
    console.log("handleSelectedItem: ", selectedItemIds);
    let currentSelectedItem = [...selectedItemIds];
    if (currentSelectedItem.includes(item.id)) {
      currentSelectedItem = currentSelectedItem.filter((i: any) => i !== item.id);
    } else {
      currentSelectedItem.push(item.id);
    }
    console.log("handleSelectedItem: ", currentSelectedItem);
    setSelectedItemIds(currentSelectedItem);
  };

  const handleSearchData = () => {
    props.onEvent({
      message: "HandleModRecontinueMedication",
      params: {
        action: "SEARCH",
        doctor: selectedDoctor?.id || "",
        division: selectedDivision?.id || "",
        card: props.card,
      },
    });
  };

  const mapDoctorOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.name_code,
      text: item.name_code,
    }));
  };

  const mapDivisionOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.code,
      value: item.name_code,
      text: item.name_code,
    }));
  };

  const handleChangeChecked = (e: any, data: any) => {
    console.log("handleChangeChecked: ", e, data);
    if (data.name === "doctor") {
      setSelectedDoctor(null);
      setCheckedDoctor(data.checked);
    }
    if (data.name === "division") {
      setSelectedDivision(null);
      setCheckedDivision(data.checked);
    }
  };

  console.log("MedRecontinueMed props: ", props);

  return (
    <ModRecontinueMedUX
      // data
      recontinueMedData={drugContinuePlanItems}
      // function
      onSearchRecontinueMed={handleSearchData}
      // onSelectRecontinueMed={(state: any, rowInfo?: any, column?: undefined, instance?: any) => {}}
      onClickAddToOrder={() => {
        props.onEvent({
          message: "HandleModRecontinueMedication",
          params: {
            action: "ADD_TO_ORDER",
            card: props.card,
            selectedItems: selectedItemIds,
            orderType: props.orderType,
          },
        });
      }}
      onClickCancel={() => {
        props.onEvent({
          message: "HandleModRecontinueMedication",
          params: { action: "CANCEL", card: props.card },
        });
      }}
      // component
      doctorSearchBox={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            label={intl.formatMessage({ id: "แพทย์" })}
            style={{ marginRight: "5px" }}
            name="doctor"
            onChange={handleChangeChecked}
            checked={checkedDoctor}
          />
          <div style={{ flex: "1" }}>
            <SearchBoxDropdown
              type="Doctor"
              id="1"
              style={{ width: "100%", flex: "1" }}
              fluid={true}
              label=""
              disabled={!checkedDoctor}
              toDisplay={(item: any) => `${item.name_code}`}
              onEvent={props.onEvent}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDoctor}
              setSelectedItem={(value: any, key: any, obj: any) => {
                if (value === null && key === null) {
                  setSelectedDoctor(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Doctor_1?.find(
                  (item: any) => item.id === key
                );
                setSelectedDoctor(doctor);
              }}
              useWithKey={true}
              mapOptions={mapDoctorOptions}
            />
          </div>
        </div>
      }
      divisionSearchBox={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            label={intl.formatMessage({ id: "แผนก" })}
            style={{ marginRight: "5px" }}
            name="division"
            onChange={handleChangeChecked}
            checked={checkedDivision}
          />
          <div style={{ flex: "1" }}>
            <SearchBoxDropdown
              type="Division"
              id="1"
              style={{ width: "100%" }}
              fluid={true}
              label=""
              disabled={!checkedDivision}
              toDisplay={(item: any) => `${item.name_code}`}
              onEvent={props.onEvent}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDivision}
              setSelectedItem={(value: any, key: any, obj: any) => {
                if (value === null && key === null) {
                  setSelectedDivision(null);
                  return;
                }
                let division = props.searchedItemListWithKey?.Division_1?.find(
                  (item: any) => item.code === key
                );
                setSelectedDivision(division);
              }}
              useWithKey={true}
              mapOptions={mapDivisionOptions}
            />
          </div>
        </div>
      }
      languageUX={props.languageUX}
    />
  );
};

export default ModRecontinueMed;

import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown
} from 'semantic-ui-react'

const ModDrugInteractionUX = (props: any) => {
    return(
      <div
        id="ModDrugInteractionUX-div-0"
        style={{width: "100%", height: "100%"}}>
        <div
          id="ModDrugInteractionUX-div-1"
          style={{backgroundColor: "#da0000", color: "white", fontWeight: "bold", fontSize: "22px", padding: "15px"}}>
          พบรายการยา Drug Interaction
        </div>
        <div
          id="ModDrugInteractionUX-div-2"
          style={{width: "100%"}}>
          {props.drugInteractionList}
        </div>
        <div
          id="ModDrugInteractionUX-div-11"
          style={{width: "100%"}}>
          {props.severityType}
        </div>
        <div
          id="ModDrugInteractionUX-div-12"
          style={{width: "100%"}}>
          {props.affectSummary}
        </div>
        <div
          id="ModDrugInteractionUX-div-13"
          style={{width: "100%"}}>
          {props.management}
        </div>
        <div
          id="ModDrugInteractionUX-div-3"
          style={{fontWeight: "bold", fontSize: "18px", padding: "15px 20px"}}>
          {props.orderItemId? "เหตุผลการสั่งใช้ยาจากแพทย์" : "กรุณาระบุเหตุผลการใช้ยา"}
        </div>
        <div
          id="ModDrugInteractionUX-div-4"
          style={{width: "100%", padding: "10px 30px"}}>
          
          <Dropdown
            allowAdditions={true}
            disabled={!props.isEditable}
            fluid={true}
            id="ModDrugInteractionUX-Dropdown-14"
            onAddItem={props.onAddItem}
            onChange={props.onNoteChange}
            options={props.drugInteractReasonOptions}
            search={true}
            selection={true}
            style={{width: "100%"}}
            value={props.note}>
          </Dropdown>
        </div>
        <div
          id="ModDrugInteractionUX-div-5"
          style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          
          <div
            id="ModDrugInteractionUX-div-7"
            style={{padding: "15px 10px"}}>
            
            <Button
              className="inverted big"
              color="green"
              disabled={props.orderItemId && !props.note}
              id="ModDrugInteractionUX-Button-9"
              onClick={props.onApprove}
              style={{display: props.isEditable? ""  : "none"}}>
              {props.orderItemId?"แก้ไข" : "ยืนยัน"}
            </Button>
          </div>
          <div
            id="ModDrugInteractionUX-div-8"
            style={{padding: "15px 10px"}}>
            
            <Button
              className="inverted big"
              color="red"
              id="ModDrugInteractionUX-Button-10"
              onClick={props.onDeny}>
              {props.isEditable? "ยกเลิก" : "ปิดหน้าต่าง"}
            </Button>
          </div>
        </div>
      </div>
    )
}


export default ModDrugInteractionUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 06 2025 10:38:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "พบรายการยา Drug Interaction"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#da0000\", color: \"white\", fontWeight: \"bold\", fontSize: \"22px\", padding: \"15px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugInteractionList"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderItemId? \"เหตุผลการสั่งใช้ยาจากแพทย์\" : \"กรุณาระบุเหตุผลการใช้ยา\""
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-3"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"18px\", padding: \"15px 20px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-4"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"10px 30px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-5"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-7"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-8"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderItemId?\"แก้ไข\" : \"ยืนยัน\""
        },
        "className": {
          "type": "value",
          "value": "inverted big"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.orderItemId && !props.note"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-Button-9"
        },
        "onClick": {
          "type": "code",
          "value": "props.onApprove"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isEditable? \"\"  : \"none\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isEditable? \"ยกเลิก\" : \"ปิดหน้าต่าง\""
        },
        "className": {
          "type": "value",
          "value": "inverted big"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-Button-10"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeny"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.severityType"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-11"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.affectSummary"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-12"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.management"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.isEditable"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModDrugInteractionUX-Dropdown-14"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddItem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onNoteChange"
        },
        "options": {
          "type": "code",
          "value": "props.drugInteractReasonOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 14,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": false,
  "memo": false,
  "name": "ModDrugInteractionUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 60
}

*********************************************************************************** */

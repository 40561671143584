import React, { useMemo, useEffect, useState } from "react";
import ModDrugLabInteractionUX from "./ModDrugLabInteractionUX";
import { useIntl } from "react-intl";

const ModDrugLabInteraction = (props: any) => {
  const [drugLabReasonOptions, setDrugLabReasonOptions] = useState<any[]>([]);

  const intl = useIntl();

  useEffect(() => {
    const note = props.modDrugLabInteraction?.note || "";
    const options = [
      ...(props.masterOptions?.drugLabReason || []),
      { key: note, text: note, value: note },
    ];
    const arrayUniqueByKey = [
      ...new Map(
        options.flatMap((option) => (option.value ? [[option.value, option]] : []))
      ).values(),
    ];

    setDrugLabReasonOptions(arrayUniqueByKey);
  }, [props.masterOptions?.drugLabReason]);

  const drugLabInteractionList = useMemo(() => {
    return (
      <div style={{ padding: "15px 20px" }}>
        {(props.modDrugLabInteraction?.interactionItems || []).map((item: any, index: any) => {
          return (
            <div style={{ display: "flex", fontSize: "18px", lineHeight: "2.4rem" }}>
              <div style={{ width: "25px", textAlign: "right", paddingRight: "10px" }}>{`${
                index + 1
              }. `}</div>
              <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
                <span style={{ color: "red", fontWeight: "bold" }}>{item.drug_name}</span>
                <span>{item.lab_warning_summary}</span>
                <span>{item.detail}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [props.modDrugLabInteraction?.interactionItems]);

  const handleAdditionOptions = (e: any, v: any) => {
    setDrugLabReasonOptions([
      ...drugLabReasonOptions,
      { key: v.value, text: v.value, value: v.value },
    ]);
  };

  console.log("ModDrugLabInteraction: ", props, drugLabInteractionList);
  return (
    <ModDrugLabInteractionUX
      // data
      drugLabInteractionList={drugLabInteractionList}
      orderItemId={
        props.modDrugLabInteraction?.orderItem?.id ||
        props.modDrugLabInteraction?.orderItem?.note_drug_lab_interaction
      }
      isEditable={props.isEditable}
      note={props.modDrugLabInteraction?.note}
      drugLabReasonOptions={drugLabReasonOptions}
      // function
      onNoteChange={(e: any, data: any) => {
        props.setProp("modDrugLabInteraction.note", data.value);
      }}
      onApprove={() => {
        if (
          props.modDrugLabInteraction.orderItem?.id ||
          props.modDrugLabInteraction?.orderItem?.note_drug_lab_interaction
        ) {
          props.onEvent({
            message: "AddToOrder",
            params: {
              editItem: {
                note_drug_lab_interaction: props.modDrugLabInteraction?.note,
              },
              drugIndex: props.modDrugLabInteraction?.orderItem.drugIndex,
              orderType: props.orderType,
              card: "CardDrugOrder",
            },
          });
          props.onEvent({
            message: "HandleModDrugLabInteraction",
            params: { action: "close" },
          });
        } else {
          props.onEvent({
            message: "RunSequence",
            params: {
              sequence: "DrugSelect",
              feedback: "confirm",
              note: props.modDrugLabInteraction?.note,
            },
          });
        }
      }}
      onDeny={() => {
        if (
          props.modDrugLabInteraction.orderItem?.id ||
          props.modDrugLabInteraction?.orderItem?.note_drug_lab_interaction
        ) {
          props.onEvent({
            message: "HandleModDrugLabInteraction",
            params: { action: "close" },
          });
        } else {
          props.onEvent({
            message: "RunSequence",
            params: { sequence: "DrugSelect", feedback: "cancel" },
          });
        }
      }}
      onAddItem={handleAdditionOptions}
      languageUX={props.languageUX}
    />
  );
};

export default ModDrugLabInteraction;
